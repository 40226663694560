import React from 'react';
import { GatsbyImage } from "gatsby-plugin-image";


export default function hero({heading, background, signature, signatureDisc, children}){
    return (    
        <section className="hero">
            <div className="hero__background-container">
                <GatsbyImage image={background} className="hero__background" alt=''></GatsbyImage>
            </div>
            <div className="wrapper wrapper--hero">
                <div className="hero__content">
                    <h1>{heading}</h1>
                    <div className="hero__body">
                        {children}
                    </div>
                </div>
                <div className="signature__container">
                    {signature  ? <GatsbyImage image={signature} alt=''></GatsbyImage> : <></>}
                    {signatureDisc}
                </div>
            </div>

        </section>
        
    );
}