import React from 'react'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';

export default function quote({portrait, patientInfo, position, className, backgroundImage, children}){
    return (
        <section className={"quote " + className}>
            <div className="wrapper">
                <div className={"quote__container " + position}>
                    <div className="portrait">
                        {backgroundImage ? 
                            <StaticImage src='../../images/aurinia-alliance-icon.png' width={300} className='aurinia-alliance-icon' alt=""></StaticImage>
                            : <></>
                        }
                        <GatsbyImage image={portrait} className='portrait__img' alt=""></GatsbyImage>
                    </div>
                    <div className="quote-text__container">
                        {children}
                        <p className="patient-name">
                            &ndash; {patientInfo}
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

quote.defaultProps = {

}