import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// Analytics
import Gtm from '@components/utility/GTM.jsx'
// Style Imports
import "./Video.scss"

const Video = ({
	src,
	type,
	poster,
	track,
    title,
	controllList
}) => {

    useEffect(() => {
        const video = document.querySelector('video');
        if ('dataLayer' in window && video) {
            Gtm.initVideo(video);
        }
    }, [])
	return (
		<div className='video'>
			<video playsInline controls {...(poster ? { poster: `${poster}` } : {})} title={title} controlsList={controllList}>
				<source src={src} type={type} />
				{track.src ? (
					<track
						src={track.src}
						kind={track.kind}
						label={track.label}
						srclang={track.srclang}
						controllList={track.controllList}
						default
					/>
				) : (
					''
				)}
			</video>
		</div>
	);
}

Video.defaultProps = {
	src: '',
	type: 'video/mp4',
	poster: '',
	track: [],
	controllList: 'nodownload',
};

Video.propTypes = {
	src: PropTypes.string.isRequired,
	type: PropTypes.string,
	poster: PropTypes.string,
	track: PropTypes.array,
	controllList: PropTypes.string,
};

export default Video;
