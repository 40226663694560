import React from 'react';
// Content Imports
import Anchor from "@components/content/Anchor.jsx"

const isiHead = () => {
	return (
    <>                    
        <h2>What is LUPKYNIS<sup>&reg;</sup> (voclosporin)?</h2>
        <p>LUPKYNIS is a prescription medicine used with other medicines to treat adults with active lupus nephritis. LUPKYNIS should not be taken with a medicine called cyclophosphamide. Talk with your healthcare provider if you are not sure if you take this medicine.</p>
        <p><strong>Please see <Anchor link="https://www.auriniapharma.com/lupkynis-prescribing-information" isExternal={true}>Prescribing Information</Anchor>, including Boxed Warning, and <Anchor link="https://www.auriniapharma.com/lupkynis-medication-guide" isExternal={true} >Medication Guide</Anchor> <Anchor link="https://www.lupkynis.com/pdfs/spanish-medication-guide.pdf" isExternal={true}>(Guía del medicamento)</Anchor> for LUPKYNIS.</strong></p>

        <h2>Important Safety Information</h2>
        <p><strong>LUPKYNIS<sup>&reg;</sup> (voclosporin) can cause serious side effects, including:</strong></p>
        <ul>
            <li><strong>Increased risk of cancer.</strong> People who take LUPKYNIS have an increased risk of getting some kinds of cancer, including skin and lymph gland cancer (lymphoma). Limit and avoid exposure to sunlight and UV light such as tanning machines. Wear protective clothing and use a sunscreen.</li>
            <li><strong>Increased risk of infection.</strong> LUPKYNIS is a medicine that affects your immune system. LUPKYNIS can lower the ability of your immune system to fight infections. Serious infections can happen in people taking LUPKYNIS that can lead to hospitalizations and can cause death. <strong>Call your healthcare provider right away</strong> if you have symptoms of an infection such as fever, sweats or chills, cough or flu-like symptoms, muscle aches, or warm, red, or painful areas on your skin.</li>
        </ul>
    </>
    );
};

export default isiHead;